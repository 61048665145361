<div class="profile-page" *ngIf="profile">
  <div class="user-info">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-md-10 offset-md-1">
          <img [src]="profile.image" class="user-img" />
          <h4>{{ profile.username }}</h4>
          <p>{{ profile.bio }}</p>
          <app-follow-button
            *ngIf="!isUser"
            [profile]="profile"
            (toggle)="onToggleFollowing($event)"
          >
          </app-follow-button>
          <a
            [routerLink]="['/settings']"
            *ngIf="isUser"
            class="btn btn-sm btn-outline-secondary action-btn"
          >
            <i class="ion-gear-a"></i> Edit Profile Settings
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-md-10 offset-md-1">
        <div class="articles-toggle">
          <ul class="nav nav-pills outline-active">
            <li class="nav-item">
              <a
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                [routerLink]="['/profile', profile.username]"
              >
                My Posts
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                [routerLink]="['/profile', profile.username, 'favorites']"
              >
                Favorited Posts
              </a>
            </li>
          </ul>
        </div>

        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
